import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MaterialTable ,{ MTableToolbar }from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import { setAgentSale } from '../../../../redux/actions/productActions';
import { ExportToCsv } from "export-to-csv";
import close from "../../../../assets/images/close.png"
const AgentSales = () => {
  const [load, setLoad] = useState(false);
  const [agentList, setAgentList] = useState([]);
  const [agentListCall, setAgentListCall] = useState(true);
  const [empkey, setEmpkey] = useState(true);

  const [transactionClick, setTransactionClick] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [transactionLoad, setTransactionLoad] = useState(false);
   const [anchorEl, setAnchorEl] = React.useState(null);
   const [agentId, setAgentId] = useState(true);
     const [downloadLoad, setDownloadLoad] = useState(false);

   const open = Boolean(anchorEl);

    let username = localStorage.getItem(
      "CognitoIdentityServiceProvider.62lp4ced9l2svdvj6b58edb06a.LastAuthUser"
    );
    let id_token = localStorage.getItem(
      "CognitoIdentityServiceProvider.62lp4ced9l2svdvj6b58edb06a." +
        username +
        ".idToken"
    );
  const dataList = useSelector((state) => state);
  const dispatch = useDispatch();
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "Agent Sales"
  };
  const csvExporter = new ExportToCsv(options);
  async function fetchList() {
    setLoad(true);
    const request = await axios.get(
      config.regulatorAgentSales,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    dispatch(setAgentSale(request.data));
    setAgentList(request.data);
    setLoad(false);
  }
  async function fetchDetailedList(agent_id) {
    setTransactionLoad(true);
    const request = await axios.get(
      config.regulatorAgentSalesDetailed+agent_id,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    // dispatch(setTransactionList(request.data));
    setTransactionList(request.data);
    setTransactionLoad(false);
  }
  async function downloadList() {
  setDownloadLoad(true);
    const request = await axios.get(
      config.regulatorAgentSales,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
  
    csvExporter.generateCsv(request.data);
    setDownloadLoad(false);
  }
  async function downloadDetailedList(agent_id) {
  
    const request = await axios.get(
      config.regulatorAgentSalesDetailed+ agent_id,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
  
    csvExporter.generateCsv(request.data);
  }
  const transaction = (agent_id) => {
    setAnchorEl(null);
    setTransactionList([]);
    setTransactionClick(true);
    setAgentId(agent_id);
    document.getElementById("modalTransBtn").click();
  };
  useEffect(() => {
    if (agentListCall === true) {
      if (dataList.channels.agentSale.length > 0) {
        setAgentList(dataList.channels.agentSale);
      } else { 
        setLoad(true);
        setAgentListCall(false);

        fetchList();
      }
      
    }
    if (transactionClick === true) {
      setTransactionClick(false);
      setTransactionLoad(true);

      fetchDetailedList(agentId);
    }
  }, [
      agentListCall,
      agentList,
      transactionClick
    ]);


    const handleClose = () => {
      setAnchorEl(null);
    
    };
    const GetActionFormat = (cell) => {
      return (
        <div>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            // onClick={handleClick}
            onClick={e => handleClick(e, cell.agent_id)}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      );
    };
    const handleClick = (event, agent_id) => {
      console.log(agent_id);
      setEmpkey(agent_id);
      setAnchorEl(event.currentTarget);
    };
  const columns = [
    {
      field: "agent_id",
      title: "Agent ID"
    },
    {
      field: "agent_firstname",
      title: "First Name"
    },
    {
      field: "agent_lastname",
      title: "Last Name"
    },
    {
      field: "transaction_count",
      title: "No of Sales"
    },

    {
      field: "",
      title: "Action",
      render: rowData => GetActionFormat(rowData)
    }
  ];

 const transactionColumns=[
  {
    field: "month",
    title: "Month"
  },
  {
    field: "year",
    title: "Year"
  },
  {
    field: "transaction_count",
    title: "No of Sales"
  },
  ]

  return (
    <ContentWrapper>
      {/* <div className="comingSoon clearfix">Coming soon...</div> */}
      <span className="pageheading">Agent Sales</span>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        // onClick={handleClick}
        style={{ display: "none" }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => transaction(empkey)}>
          <label>Detailed View</label>
        </MenuItem>
      </Menu>
      <button
        // className={styles.addNewBtn}
        data-toggle="modal"
        id="modalTransBtn"
        data-target="#modalTransaction"
        style={{ display: "none" }}
      >
        Transaction2
      </button>

      <div
        className="modal fade"
        id="modalTransaction"
        role="dialog"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered dialogWidth">
          <div className="modal-content modal-content-bg">
            <div className="modalHeading tableModalHeading">
              Monthly Performance
            </div>
            <img
              src={close}
              alt=""
              className="modalCloseBtn"
              data-dismiss="modal"
              id="modalCloseBtn"
            />

            <div className="tableModalContentDiv">
              {transactionLoad === true && <div className="loader" />}
              {transactionLoad === true && (
                <div style={{ titleAlign: "center" }}>
                  Loading Detailed View
                </div>
              )}
              {transactionLoad === false && (
                <div>
                  {transactionList.length > 0 ? (
                    <MaterialTable
                      // other props
                      components={{
                        Toolbar: props => (
                          <div style={{ backgroundColor: "#f6f6e6" }}>
                            <MTableToolbar {...props} />
                          </div>
                        )
                      }}
                      columns={transactionColumns}
                      data={transactionList}
                      title={false}
                      options={{
                        pageSize: 10,
                        searchFieldAlignment: "left",
                        searchFieldStyle: {
                          backgroundColor: "white"
                        }
                      }}
                      actions={[
                        {
                          icon: "save_alt",
                          tooltip: "Export Data",
                          isFreeAction: true,
                          iconProps: {
                            style: {
                              backgroundColor: "#eb5757",
                              width: "35px",
                              height: "35px",
                              borderRadius: "20px",
                              paddingTop: "6px",
                              color: "white"
                            }
                          },
                          onClick: () => downloadDetailedList(agentId)
                        },
                        {
                          icon: "refresh",
                          tooltip: "Refresh Data",
                          isFreeAction: true,
                          iconProps: {
                            style: {
                              backgroundColor: "#41b5c2",
                              width: "35px",
                              height: "35px",
                              borderRadius: "20px",
                              paddingTop: "6px",
                              color: "white"
                            }
                          },
                          onClick: () => fetchDetailedList(agentId)
                        }
                      ]}
                    />
                  ) : (
                    <div>No Data Found</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="tableBody clearfix">
        {load === true && <div className="loader" />}
        {load === true && (
          <div style={{ textAlign: "center" }}>Loading List</div>
        )}

        {load === false && (
          <MaterialTable
            // other props
            components={{
              Toolbar: props => (
                <div style={{ backgroundColor: "#f6f6e6" }}>
                  <MTableToolbar {...props} />
                  {downloadLoad === true && <div className="loaderDwnld" />}
                  {downloadLoad === true && (
                    <div
                      style={{
                        textAlign: "center",
                        position: "absolute",
                        right: "50px"
                      }}
                    >
                      downloading
                    </div>
                  )}
                  <div style={{ display: "inline-block" }}></div>
                </div>
              )
            }}
            columns={columns}
            data={agentList}
            options={{
              pageSize: 10,
              searchFieldAlignment: "left",
              searchFieldStyle: {
                backgroundColor: "white"
              }
            }}
            title={false}
            actions={[
              {
                icon: "save_alt",
                tooltip: "Export Data",
                isFreeAction: true,
                iconProps: {
                  style: {
                    backgroundColor: "#eb5757",
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    paddingTop: "6px",
                    color: "white"
                  }
                },
                onClick: () => downloadList()
              },
              {
                icon: "refresh",
                tooltip: "Refresh Data",
                isFreeAction: true,
                iconProps: {
                  style: {
                    backgroundColor: "#41b5c2",
                    width: "35px",
                    height: "35px",
                    borderRadius: "20px",
                    paddingTop: "6px",
                    color: "white"
                  }
                },
                onClick: () => fetchList()
              }
            ]}
          />
        )}
      </div>
    </ContentWrapper>
  );
};

export default AgentSales;

