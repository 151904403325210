import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import React, { useState, useEffect } from "react";

import Amplify from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import awsconfig from "../../aws-exports";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";


// import Wrapper from "../Common/Wrapper";
// import Login from "../Common/Login";
import FileProcess from "../Admin/FileProcess";
import AgentRules from "../Admin/AgentRules";
import ChannelRules from "../Admin/ChannelRules";

import RegulatorHomePage from "../Regulator/RegulatorHomePage";
import ShareWalletBalance from "../Regulator/Payables/ShareWalletBalance";
import AdminReserve from "../Regulator/Payables/AdminReserve";
import ContributionAllocationYTD from "../Regulator/Payables/ContributionAllocationYTD";
import AggregateReportByChannel from "../Regulator/Payables/AggregateReportByChannel";
import TransactionProcessingRule from "../Regulator/Payables/TransactionProcessingRule";
import ShareplusSummary from "../Regulator/Payables/ShareplusSummary";
import OpsDashboard from "../Regulator/Payables/OpsDashboard";
import NewOpsDashboard from "../Regulator/Payables/NewOpsDashboard";
import Member from "../Regulator/Payables/Member";
import MonthlyNeedsAggregate from "../Regulator/NeedsAndContributions/MonthlyNeedsAggregate";
import Needs from "../Regulator/NeedsAndContributions/Needs";
import NeedsRequests from "../Regulator/NeedsAndContributions/NeedsRequests";
import SalesByPlan from "../Regulator/HouseHold/SalesByPlan"
import HouseHoldReport from "../Regulator/HouseHold/HouseHoldReport";
import PastDue from "../Regulator/HouseHold/PastDue";
import HouseHoldQuery from "../Regulator/HouseHold/HouseHoldQuery";
import HouseHoldAggregateReport from "../Regulator/HouseHold/HouseHoldAggregateReport";
import MemberAggregateReport from "../Regulator/HouseHold/MemberAggregateReport";

import AgencyCommission from "../Regulator/Agent/AgencyCommission";
import AgentCommission from "../Regulator/Agent/AgentCommission";
import AgentSales from "../Regulator/Agent/AgentSales";
import CommissionReport from "../Regulator/Agent/CommissionReport";

import RuleEditor from "../Regulator/RuleEditor";

import Settings from "../Common/Settings"
import logo from "../../assets/images/logo.png";

Amplify.configure(awsconfig);

function App() {

  const DefaultContainer = () => (
    <div>
      <div>
        <Route exact path="/FileProcess" component={FileProcess} />
        <Route exact path="/AgentRules" component={AgentRules} />
        <Route exact path="/ChannelRules" component={ChannelRules} />

        <Route exact path="/RegulatorHomePage" component={RegulatorHomePage} />
        <Route
          exact
          path="/Payables/ShareWalletBalance"
          component={ShareWalletBalance}
        />
        <Route exact path="/Payables/AdminReserve" component={AdminReserve} />
        <Route
          exact
          path="/Payables/ContributionAllocationYTD"
          component={ContributionAllocationYTD}
        />
        <Route
          exact
          path="/Payables/AggregateReportByChannel"
          component={AggregateReportByChannel}
        />
        <Route
          exact
          path="/Payables/TransactionProcessingRule"
          component={TransactionProcessingRule}
        />
        <Route
          exact
          path="/Payables/ShareplusSummary"
          component={ShareplusSummary}
        />
        <Route exact path="/Payables/OpsDashboard" component={OpsDashboard} />
        <Route
          exact
          path="/Payables/NewOpsDashboard"
          component={NewOpsDashboard}
        />
        <Route
          exact
          path="/Payables/Member"
          component={Member}
        />

        <Route
          exact
          path="/NeedsAndContributions/MonthlyNeedsAggregate"
          component={MonthlyNeedsAggregate}
        />
        <Route exact path="/NeedsAndContributions/Needs" component={Needs} />
        <Route
          exact
          path="/NeedsAndContributions/NeedsRequests"
          component={NeedsRequests}
        />

        <Route
          exact
          path="/HouseHold/HouseHoldReport"
          component={HouseHoldReport}
        />
        <Route exact path="/HouseHold/PastDue" component={PastDue} />
        <Route exact path="/HouseHold/SalesByPlan" component={SalesByPlan} />
        <Route
          exact
          path="/HouseHold/HouseHoldQuery"
          component={HouseHoldQuery}
        />
        <Route
          exact
          path="/HouseHold/HouseHoldAggregateReport"
          component={HouseHoldAggregateReport}
        />
        <Route
          exact
          path="/HouseHold/MemberAggregateReport"
          component={MemberAggregateReport}
        />

        <Route
          exact
          path="/Agent/AgencyCommission"
          component={AgencyCommission}
        />
        <Route
          exact
          path="/Agent/AgentCommission"
          component={AgentCommission}
        />
        <Route exact path="/Agent/AgentSales" component={AgentSales} />
        <Route
          exact
          path="/Agent/CommissionReport"
          component={CommissionReport}
        />
        <Route exact path="/RuleEditor" component={RuleEditor} />

        <Route exact path="/Settings" component={Settings} />
      </div>
    </div>
  );

  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    localStorage.setItem('PII', true)
    return onAuthUIStateChange((nextAuthState, authData) => {

      setAuthState(nextAuthState);
      setUser(authData);
      console.log(nextAuthState);
      console.log(authData)
      if (authData !== undefined) {
        localStorage.setItem(
          "userRole",
          "regulator"
        );
        // localStorage.setItem(
        //   "userRole",
        //   authData.signInUserSession.idToken.payload["cognito:username"]
        // );
      }

    });
  }, []);

  return (
    <div className="App">
      {/* <img src={logo.default} alt="" className="homeLogo" /> */}

      <AmplifyAuthenticator>
        <div
          className="App"
          slot="sign-in"
          style={{ backgroundColor: "white" }}
        >
          <img src={logo.default} alt="" className="homeLogo" />
          <AmplifySignIn
            headerText="Sign in to your account"
            slot="sign-in"
            hideSignUp
          ></AmplifySignIn>
        </div>

        <Router>
          <Switch>
            {authState === AuthState.SignedIn &&
              user &&
              user.signInUserSession.idToken.payload["cognito:username"] ===
                "admin" && (
                <Route exact path="/" component={FileProcess} detail={user} />
              )}
            {authState === AuthState.SignedIn &&
              user && (
                <Route exact path="/" component={OpsDashboard} detail={user} />
              )}
            {/* <Route path="/login" component={Login} /> */}
            <Route component={DefaultContainer} />
            <Route>404 Not Found!</Route>
          </Switch>
        </Router>
      </AmplifyAuthenticator>
    </div>
  );
}

export default App;