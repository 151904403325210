import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";





const Member = () => {

  const [agentListCall, setAgentListCall] = useState(true);
  const [url, setUrl] = useState(null);
  const [showFullScreen, setShowFullScreen] = useState(false);


   let username = localStorage.getItem(
     "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse.LastAuthUser"
   );
   let id_token = localStorage.getItem(
     "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse." +
       username +
       ".idToken"
   );

   window.addEventListener("message", (event)=>
   {

    let isFullScreen = JSON.parse(event.data).isFullScreen;
    console.log(event.data)
    console.log(isFullScreen);


    setShowFullScreen(isFullScreen)
   }
   )


  return (
    <ContentWrapper>
      {/* <span className="pageheading">OPS Dashboard </span> */}
      <br />
      <br />
      <iframe
        src={'https://dev.member.netwell.com:8091/MembersTab'}
        height="800px"
        width="100%"
        className={showFullScreen ? 'panel-fullscreen': ''}
        style={{ marginTop: "20px" }}
      />
    </ContentWrapper>
  );
};

export default Member;
