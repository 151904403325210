import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";







const OpsDashboard = () => {

  const [agentListCall, setAgentListCall] = useState(true);
  const [url, setUrl] = useState(null);


   let username = localStorage.getItem(
     "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse.LastAuthUser"
   );
   let id_token = localStorage.getItem(
     "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse." +
       username +
       ".idToken"
   );
   async function fetchList(dashboard_id) {
    const request = await axios.get(
      config.regulatorPayableOpsDashbard+dashboard_id,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    return request.data.EmbedUrl
   
  }
  useEffect(async () => {
    if (agentListCall === true) {
      // setLoad(true);
      setAgentListCall(false);
     
    //  let response= await fetchList("8b9d05cd-6a4a-413c-9eb9-adfd7fcc9b56");
         let response = await fetchList("3422513a-8a21-4046-b5c2-ab771d1fc063");

     setUrl(response);
    }
  }, [agentListCall]);



  return (
    <ContentWrapper>
      {/* <span className="pageheading">Enrollment Report </span> */}
      <div className="comingSoon clearfix">Coming soon...</div>
      {/* <br />
      <br />
      <iframe
        src={url}
        height="800px"
        width="100%"
        style={{ marginTop: "20px" }}
      /> */}
    </ContentWrapper>
  );
};

export default OpsDashboard;
