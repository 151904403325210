import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../Common/ContentWrapper";
import axios from "../../../../configuration/axios";
import apikey from "../../../../configuration/apikey";
import config from "../../../../configuration/config";
import { useSelector, useDispatch } from "react-redux";
import MaterialTable ,{ MTableToolbar }from 'material-table';
import PropTypes from 'prop-types';
import { makeStyles ,withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import { ExportToCsv } from "export-to-csv";

const TabPanel = (props) =>{
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    textTransform: 'none',
    // color: '#fff',
  },
  indicator: {
    backgroundColor: '#533278',
  },
  textColor:{
      color:'#533278'
    
  }
 
}));

const StyledRadio = withStyles({
  root: {
    color: "#533278",
    '&$checked': {
      color: '#533278',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);


const SalesByPlan = () => {

  const [load, setLoad] = useState(false);
  const [salesList, setSalesList] = useState([]);
  const [salesCumulativeList, setCumulativeSalesList] = useState([]);
  const [salesListCall, setSalesListCall] = useState(true);
  const [termList, setTermList] = useState([]);
  const [termCumulativeList, setCumulativeTermList] = useState([]);
    const [downloadLoad, setDownloadLoad] = useState(false);

     const classes = useStyles();
  let username = localStorage.getItem(
    "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse.LastAuthUser"
  );
  let id_token = localStorage.getItem(
    "CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse." +
      username +
      ".idToken"
  );
  const [value, setValue] =useState(0);
  const [selectedValue, setSelectedValue] = React.useState('sales');
  const dataList = useSelector((state) => state);
  const sales_options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "SalesByPlan"
  };
    const cumulative_sales_options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "CumulativeSalesByPlan"
  };
    const terms_options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "TermsByPlan"
  };
    const cumulative_terms_options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: "CumulativeTermsByPlan"
  };
  const csvExporterSales = new ExportToCsv(sales_options);
  const csvExporterTerms = new ExportToCsv(terms_options);
  const csvExporterCumulativeSales = new ExportToCsv(cumulative_sales_options);
  const csvExporterCumulativeTerms = new ExportToCsv(cumulative_terms_options);
  const handleChange = (event, newValue) => {
    console.log(event.target.innerText)
    if(event.target.innerText=="BY TERMS"){
      setSelectedValue('terms')
    }else if(event.target.innerText=="BY SALES"){
      setSelectedValue('sales')
    }else if(event.target.innerText=="BY CUMULATIVE SALES"){
      setSelectedValue('cumulative_sales')
    }
    else if(event.target.innerText=="BY CUMULATIVE TERMS"){
      setSelectedValue('cumulative_term')
    }
    setValue(newValue);
     
  };
  async function fetchList() {
    setLoad(true);
    const request = await axios.get(
      config.regulatorSaleByPlan,
     
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    // if (dataList.channels.householdPastDue.length === 0) {
    //   dispatch(setHouseholdPastDue(request.data[0].payload));
    // }
    setSalesList(request.data[0].sales);
    setTermList(request.data[0].terms)
    setCumulativeSalesList(request.data[0].cumulative_sales)
    setCumulativeTermList(request.data[0].cumulative_terms)
    setLoad(false);
  }
  async function downloadList(filter) {
    setDownloadLoad(true);

    const request = await axios.get(
      config.regulatorSaleByPlan,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apikey.xapikey,
          token: id_token
        }
      }
    );
    if(filter=="sales"){
    csvExporterSales.generateCsv(request.data[0].sales);
      setDownloadLoad(false);

    }else if(filter=="terms"){
      csvExporterTerms.generateCsv(request.data[0].terms);
      setDownloadLoad(false);
    }else if(filter=="cumulative_sales"){
      csvExporterCumulativeSales.generateCsv(request.data[0].cumulative_sales);
      setDownloadLoad(false);
    }
    else if(filter=="cumulative_terms"){
      csvExporterCumulativeTerms.generateCsv(request.data[0].cumulative_terms);
      setDownloadLoad(false);
    }
    
      
  }



  useEffect(() => {
    if (salesListCall === true) {
      setSalesListCall(false);
      if(dataList.channels.householdPastDue.length > 0 && (salesList[0] === 'null' || salesList[0] === undefined)) {
        setSalesList(dataList.channels.householdPastDue);
      } else {
        setLoad(true);
        setSalesListCall(false);

        fetchList();
      }

    }




  }, [
    salesListCall,
    salesList,
 
  ]);

  
  const columns = [
    {
      field: "year",   
      title: "Year"
  
    },
    {
      field: "month",   
      title: "Month",
  
    },
    {
      field: "total",   
      title: "Total",
  
    },
    {
      field: "1001",   
      title: "1001",
  
    },{
      field: "1003",   
      title: "1003",
  
    },{
      field: "1005",   
      title: "1005",
  
    },{
      field: "1006",   
      title: "1006",
  
    },{
      field: "1011",   
      title: "1011",
  
    },{
      field: "6001",   
      title: "6001",
  
    },{
      field: "6006",   
      title: "6006",
  
    },{
      field: "7002",   
      title: "7002",
  
    },{
      field: "7004",   
      title: "7004",
  
    },
    {
      field: "7006",   
      title: "7006",
  
    }, {
      field: "7011",   
      title: "7011",
  
    },{
      field: "7018",   
      title: "7018",
  
    },{
      field: "7020",   
      title: "7020",
  
    },{
      field: "7022",   
      title: "7022",
  
    },{
      field: "8002",   
      title: "8002",
  
    },{
      field: "8004",   
      title: "8004",
  
    },{
      field: "8006",   
      title: "8006",
  
    },{
      field: "8018",   
      title: "8018",
  
    },{
      field: "8022",   
      title: "8022",
  
    },{
      field: "9004",   
      title: "9004",
  
    },{
      field: "9005",   
      title: "9005",
  
    },
    {
      field: "9006",   
      title: "9006",
  
    },
    {
      field: "10001",   
      title: "10001",
  
    },{
      field: "10002",   
      title: "10002",
  
    },{
      field: "10003",   
      title: "10003",
  
    },{
      field: "10004",   
      title: "10004",
  
    },{
      field: "10006",   
      title: "10006",
  
    },{
      field: "10019",   
      title: "10019",
  
    },{
      field: "10021",   
      title: "10021",
  
    },{
      field: "12002",   
      title: "12002",
  
    },{
      field: "12004",   
      title: "12004",
  
    },{
      field: "12006",   
      title: "12006",
  
    },{
      field: "12011",   
      title: "12011",
  
    },{
      field: "12018",   
      title: "12018",
  
    },{
      field: "12020",   
      title: "12020",
  
    },{
      field: "12022",   
      title: "12022",
  
    },{
      field: "13001",   
      title: "13001",
  
    },{
      field: "13002",   
      title: "13002",
  
    },{
      field: "13003",   
      title: "13003",
  
    },{
      field: "13004",   
      title: "13004",
  
    },{
      field: "13017",   
      title: "13017",
  
    },{
      field: "13018",   
      title: "13018",
  
    },{
      field: "13019",   
      title: "13019",
  
    },{
      field: "13020",   
      title: "13020",
  
    },{
      field: "13021",   
      title: "13021",
  
    },{
      field: "13022",   
      title: "13022",
  
    },
  ];
  
  return (
    <ContentWrapper>
      <div className="comingSoon clearfix">Coming soon...</div>
      {/* <span className="pageheading">HouseHold Plans Report</span>

      <div className="tableBody clearfix">
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            classes={{
              indicator: classes.indicator
            }}
            aria-label="simple tabs example"
          >
            <Tab
              label={
                <span style={{ color: "#533278" }}>
                  <StyledRadio
                    checked={selectedValue === "sales"}
                    disabled
                    value="sales"
                    name="radio-button"
                    disableRipple
                    inputProps={{ "aria-label": "SALES" }}
                  />
                  BY SALES
                </span>
              }
            />
            <Tab
              label={
                <span style={{ color: "#533278" }}>
                  <StyledRadio
                    disableRipple
                    disabled
                    checked={selectedValue === "terms"}
                    value="terms"
                    name="radio-button"
                    inputProps={{ "aria-label": "TERMS" }}
                  />
                  BY TERMS
                </span>
              }
            />
            <Tab
              label={
                <span style={{ color: "#533278" }}>
                  <StyledRadio
                    disableRipple
                    disabled
                    checked={selectedValue === "cumulative_sales"}
                    value="cumulative_sales"
                    name="radio-button"
                    inputProps={{ "aria-label": "BY CUMULATIVE SALES" }}
                  />
                  BY CUMULATIVE SALES
                </span>
              }
            />
            <Tab
              label={
                <span style={{ color: "#533278" }}>
                  <StyledRadio
                    disableRipple
                    disabled
                    checked={selectedValue === "cumulative_term"}
                    value="cumulative_term"
                    name="radio-button"
                    inputProps={{ "aria-label": "BY CUMULATIVE TERMS" }}
                  />
                  BY CUMULATIVE TERMS
                </span>
              }
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          {load === true && <div className="loader" />}
          {load === true && (
            <div style={{ textAlign: "center" }}>Loading List</div>
          )}

          {load === false && (
            <MaterialTable
              // other props
              components={{
                Toolbar: props => (
                  <div style={{ backgroundColor: "#f6f6e6" }}>
                    <MTableToolbar {...props} />
                    {downloadLoad === true && <div className="loaderDwnld" />}
                    {downloadLoad === true && (
                      <div
                        style={{
                          textAlign: "center",
                          position: "absolute",
                          right: "50px"
                        }}
                      >
                        downloading
                      </div>
                    )}
                    <div style={{ display: "inline-block" }}></div>
                  </div>
                )
              }}
              columns={columns}
              data={salesList}
              options={{
                pageSize: 10,
                searchFieldAlignment: "left",
                searchFieldStyle: {
                  backgroundColor: "white"
                }
              }}
              title={false}
              actions={[
                {
                  icon: "save_alt",
                  tooltip: "Export Data",
                  isFreeAction: true,
                  iconProps: {
                    style: {
                      backgroundColor: "#eb5757",
                      width: "35px",
                      height: "35px",
                      borderRadius: "20px",
                      paddingTop: "6px",
                      color: "white"
                    }
                  },
                  onClick: () => downloadList("sales")
                },
                {
                  icon: "refresh",
                  tooltip: "Refresh Data",
                  isFreeAction: true,
                  iconProps: {
                    style: {
                      backgroundColor: "#41b5c2",
                      width: "35px",
                      height: "35px",
                      borderRadius: "20px",
                      paddingTop: "6px",
                      color: "white"
                    }
                  },
                  onClick: () => fetchList()
                }
              ]}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {load === true && <div className="loader" />}
          {load === true && (
            <div style={{ textAlign: "center" }}>Loading List</div>
          )}

          {load === false && (
            <MaterialTable
              // other props
              components={{
                Toolbar: props => (
                  <div style={{ backgroundColor: "#f6f6e6" }}>
                    <MTableToolbar {...props} />
                    {downloadLoad === true && <div className="loaderDwnld" />}
                    {downloadLoad === true && (
                      <div
                        style={{
                          textAlign: "center",
                          position: "absolute",
                          right: "50px"
                        }}
                      >
                        downloading
                      </div>
                    )}
                    <div style={{ display: "inline-block" }}></div>
                  </div>
                )
              }}
              columns={columns}
              data={termList}
              options={{
                pageSize: 10,
                searchFieldAlignment: "left",
                searchFieldStyle: {
                  backgroundColor: "white"
                }
              }}
              title={false}
              actions={[
                {
                  icon: "save_alt",
                  tooltip: "Export Data",
                  isFreeAction: true,
                  iconProps: {
                    style: {
                      backgroundColor: "#eb5757",
                      width: "35px",
                      height: "35px",
                      borderRadius: "20px",
                      paddingTop: "6px",
                      color: "white"
                    }
                  },
                  onClick: () => downloadList("terms")
                },
                {
                  icon: "refresh",
                  tooltip: "Refresh Data",
                  isFreeAction: true,
                  iconProps: {
                    style: {
                      backgroundColor: "#41b5c2",
                      width: "35px",
                      height: "35px",
                      borderRadius: "20px",
                      paddingTop: "6px",
                      color: "white"
                    }
                  },
                  onClick: () => fetchList()
                }
              ]}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {load === true && <div className="loader" />}
          {load === true && (
            <div style={{ textAlign: "center" }}>Loading List</div>
          )}

          {load === false && (
            <MaterialTable
              // other props
              components={{
                Toolbar: props => (
                  <div style={{ backgroundColor: "#f6f6e6" }}>
                    <MTableToolbar {...props} />
                    {downloadLoad === true && <div className="loaderDwnld" />}
                    {downloadLoad === true && (
                      <div
                        style={{
                          textAlign: "center",
                          position: "absolute",
                          right: "50px"
                        }}
                      >
                        downloading
                      </div>
                    )}
                    <div style={{ display: "inline-block" }}></div>
                  </div>
                )
              }}
              columns={columns}
              data={salesCumulativeList}
              options={{
                pageSize: 10,
                searchFieldAlignment: "left",
                searchFieldStyle: {
                  backgroundColor: "white"
                }
              }}
              title={false}
              actions={[
                {
                  icon: "save_alt",
                  tooltip: "Export Data",
                  isFreeAction: true,
                  iconProps: {
                    style: {
                      backgroundColor: "#eb5757",
                      width: "35px",
                      height: "35px",
                      borderRadius: "20px",
                      paddingTop: "6px",
                      color: "white"
                    }
                  },
                  onClick: () => downloadList("cumulative_sales")
                },
                {
                  icon: "refresh",
                  tooltip: "Refresh Data",
                  isFreeAction: true,
                  iconProps: {
                    style: {
                      backgroundColor: "#41b5c2",
                      width: "35px",
                      height: "35px",
                      borderRadius: "20px",
                      paddingTop: "6px",
                      color: "white"
                    }
                  },
                  onClick: () => fetchList()
                }
              ]}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {load === true && <div className="loader" />}
          {load === true && (
            <div style={{ textAlign: "center" }}>Loading List</div>
          )}

          {load === false && (
            <MaterialTable
              // other props
              components={{
                Toolbar: props => (
                  <div style={{ backgroundColor: "#f6f6e6" }}>
                    <MTableToolbar {...props} />
                    {downloadLoad === true && <div className="loaderDwnld" />}
                    {downloadLoad === true && (
                      <div
                        style={{
                          textAlign: "center",
                          position: "absolute",
                          right: "50px"
                        }}
                      >
                        downloading
                      </div>
                    )}
                    <div style={{ display: "inline-block" }}></div>
                  </div>
                )
              }}
              columns={columns}
              data={termCumulativeList}
              options={{
                pageSize: 10,
                searchFieldAlignment: "left",
                searchFieldStyle: {
                  backgroundColor: "white"
                }
              }}
              title={false}
              actions={[
                {
                  icon: "save_alt",
                  tooltip: "Export Data",
                  isFreeAction: true,
                  iconProps: {
                    style: {
                      backgroundColor: "#eb5757",
                      width: "35px",
                      height: "35px",
                      borderRadius: "20px",
                      paddingTop: "6px",
                      color: "white"
                    }
                  },
                  onClick: () => downloadList("cumulative_terms")
                },
                {
                  icon: "refresh",
                  tooltip: "Refresh Data",
                  isFreeAction: true,
                  iconProps: {
                    style: {
                      backgroundColor: "#41b5c2",
                      width: "35px",
                      height: "35px",
                      borderRadius: "20px",
                      paddingTop: "6px",
                      color: "white"
                    }
                  },
                  onClick: () => fetchList()
                }
              ]}
            />
          )}
        </TabPanel>
      </div> */}
    </ContentWrapper>
  );
};

export default SalesByPlan;

