import React, { useState, useEffect } from "react";
import { Navigation } from "react-minimal-side-navigation";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../Header";
import DehazeIcon from "@material-ui/icons/Dehaze";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import Avatar from "react-avatar";


function Wrapper({ children }) {
  const history = useHistory();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [isRole, setIsRole] = useState("");
  const [isName, setIsName] = useState("");
  useEffect(() => {

    setTimeout(function() {
      setIsRole(localStorage.getItem("userRole"));
      setIsName(localStorage.getItem("CognitoIdentityServiceProvider.6lvueb8rnsjg1ps043r7fivpse.LastAuthUser"))
    }, 1);
    



    if (window.innerWidth < 1024) {
      document.getElementById('content').style.width = "100%";
      document.getElementById('headerDiv').style.width = "100%";
    } else {
      document.getElementById('headerDiv').style.width = "100%";
      document.getElementById('content').style.width = "80%"
      document.getElementById('content').style.marginLeft = "20%"
      document.getElementById('sideBarContent').style.width = "20%"
      document.getElementById('sideBarContent').style.position = "fixed"

    }
  }, [location]);

  const handleClick = () => {
    if (isSidebarOpen === true) {
      document.getElementById('headerDiv').style.width = "100%";
      document.getElementById('content').style.width = "100%";
      document.getElementById('content').style.marginLeft = "0%";
    } else if (isSidebarOpen !== true && window.innerWidth < 1024) {
      document.getElementById('headerDiv').style.width = "100%%";
      document.getElementById('content').style.width = "100%"
      document.getElementById('content').style.marginLeft = "0%"

    } else {
      document.getElementById('headerDiv').style.width = "100%";
      document.getElementById('content').style.width = "80%";
      document.getElementById('content').style.marginLeft = "20%"


    }
    setIsSidebarOpen(!isSidebarOpen);
  }


  setInterval(() => {
    if (window.innerWidth < 1024) {
      document.getElementById('content').style.width = "100%";
      document.getElementById('headerDiv').style.width = "100%";
    } else {
      // document.getElementById('headerDiv').style.width = "72%";
      // document.getElementById('content').style.width = "100%"
      // document.getElementById('content').style.marginLeft = "28%"
      // document.getElementById('sideBarContent').style.width = "28%"
      // document.getElementById('sideBarContent').style.position = "fixed"

    }
  }, 2000);


  return (
    <div className="relative min-h-screen">
      <main className="w-full min-h-screen">
        {/* h-screen  */}
        <div className="flex min-h-screen">
          <React.Fragment>
            <div
              className={`fixed inset-y-0 left-0 z-30 w-72 overflow-y-auto transition duration-300 ease-out transform translate-x-0 border-r-2 lg:translate-x-0 lg:static lg:inset-0 ${
                isSidebarOpen
                  ? "ease-out translate-x-0 block"
                  : "ease-in -translate-x-full hidden"
              }`}
              id="sideBarContent"
              style={{
                backgroundColor: "#fff",
                boxShadow: "0 0 28px 0 rgb(86 61 124 / 13%)"
              }}
            >
              <div className="flex items-center justify-center text-center py-6">
                <span
                  className="mx-2 text-2xl font-semibold text-black"
                  style={{ width: "80%", margin: "0 auto", textAlign: "left" }}
                >
                  <Avatar name={isName} size="50" round={true} />
                  &nbsp;&nbsp;
                  <span className="sidebarName">Hello, {isName}</span>
                </span>
              </div>
              {isRole === "admin" && (
                <Navigation
                  activeItemId={location.pathname}
                  onSelect={({ itemId }) => {
                    history.push({
                      pathname: itemId,
                      state: { detail: "admin" }
                    });
                  }}
                  items={[
                    {
                      title: "File Process",
                      itemId: "/FileProcess"
                    },
                    {
                      title: "Agent Rules",
                      itemId: "/AgentRules"
                    },
                    {
                      title: "Channel Rules",
                      itemId: "/ChannelRules"
                    }
                  ]}
                />
              )}
              {isRole === "regulator" && (
                <Navigation
                  activeItemId={location.pathname}
                  onSelect={({ itemId }) => {
                    history.push({
                      pathname: itemId,
                      state: { detail: "regulator" }
                    });
                  }}
                  items={[
                    // {
                    //   title: "Home",
                    //   itemId: "/RegulatorHomePage"
                    // },
                    {
                      title: "Payables",
                      itemId: "/Payables/ShareWalletBalance",
                      subNav: [
                        {
                          title: "Share Wallet Balance",
                          itemId: "/Payables/ShareWalletBalance"
                        },
                        {
                          title: "Ops Admin",
                          itemId: "/Payables/AdminReserve"
                        },
                        {
                          title: "Contribution Allocation YTD",
                          itemId: "/Payables/ContributionAllocationYTD"
                        },
                        {
                          title: "Aggregate Report By Channel",
                          itemId: "/Payables/AggregateReportByChannel"
                        },
                        {
                          title: "Transaction Processing Rules",
                          itemId: "/Payables/TransactionProcessingRule"
                        },
                        {
                          title: "Shareplus Summary",
                          itemId: "/Payables/ShareplusSummary"
                        },

                        {
                          title: "Enrollment Report",
                          itemId: "/Payables/OpsDashboard"
                        },
                        {
                          title: "OPS Dashboard",
                          itemId: "/Payables/NewOpsDashboard"
                        },
                        {
                          title: "Rule Editor",
                          itemId: "/RuleEditor"
                        },
                        {
                          title: "Summary Report",
                          itemId: "/Payables/Member"
                        },
                      ]
                    },
                    {
                      title: "Needs & Contributions",
                      itemId: "/NeedsAndContributions/Needs",
                      subNav: [
                        {
                          title: "Needs",
                          itemId: "/NeedsAndContributions/Needs"
                        },
                        {
                          title: "Monthly Needs Aggregate",
                          itemId: "/NeedsAndContributions/MonthlyNeedsAggregate"
                        },
                        {
                          title: "Needs Requests",
                          itemId: "/NeedsAndContributions/NeedsRequests"
                        }
                      ]
                    },
                    {
                      title: "HouseHold",
                      itemId: "/HouseHold/HouseHoldReport",
                      subNav: [
                        {
                          title: "HouseHold Report",
                          itemId: "/HouseHold/HouseHoldReport"
                        },
                        {
                          title: "HouseHold Query",
                          itemId: "/HouseHold/HouseHoldQuery"
                        },
                        {
                          title: "HouseHold Aggregate Report",
                          itemId: "/HouseHold/HouseHoldAggregateReport"
                        },
                        {
                          title: "Past Due Report",
                          itemId: "/HouseHold/PastDue"
                        },
                        {
                          title: "Sales By Plan Report",
                          itemId: "/HouseHold/SalesByPlan"
                        },
                        {
                          title: "Member Monthly Report",
                          itemId: "/HouseHold/MemberAggregateReport"
                        }
                      ]
                    },
                    {
                      title: "Agent",
                      itemId: "/Agent/AgencyCommission",
                      subNav: [
                        {
                          title: " Agency Commission",
                          itemId: "/Agent/AgencyCommission"
                        },
                        {
                          title: "Agent Commission",
                          itemId: "/Agent/AgentCommission"
                        },
                        {
                          title: "Agent Sales",
                          itemId: "/Agent/AgentSales"
                        },
                        {
                          title: "Commission Reports",
                          itemId: "/Agent/CommissionReport"
                        }
                      ]
                    }
                  ]}
                />
              )}

              <div className="footer">
                Copyright 2021, CarynHealth Solutions, LLC.
              </div>
            </div>

            <div className="page-header" id="headerDiv">
              <DehazeIcon
                className="toggleSideIcon"
                fontSize="medium"
                color="white"
                onClick={() => handleClick()}
              />
              <label className="logoClsHeader">ShrPA</label>

              <Header />
            </div>
            <main className="content" id="content" style={{ width: "82%" }}>
              <div>{children}</div>
            </main>
          </React.Fragment>
        </div>
      </main>
    </div>
  );
}
export default Wrapper;